<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LuBiblios></LuBiblios>

	2019-06-08	init

-->

<template>
	<div class="LuBiblios contentModule" :class="elmClasses">
		<div class="vSpace vSpace--LuText border border--top">
			<template v-if="post">
				<div class="font font--mono font--autoSmooth" v-html="$root.getTranslatedAcfValue(post.acf, 'biblios')"></div>

				<br />

				<!-- product ist lieferbar -->
				<template v-if="post.acf.notOut">

					<LuPrice
						class="LuBiblios__price font font--mono font--sizeHugePrice"
						:price="post.acf.priceEur"
					></LuPrice>

					<div class="LuBiblios__priceInfos font font--mono font--sizeSmall font--autoSmooth">
						<span v-if="post.acf.taxRate" v-html="$t('message.inclusiveTax', { number : post.acf.taxRate })"></span>
						<br v-if="!$root.isSmallDevice" />
						<span v-html="$t('message.freeDelivery', { shippingInfosLink : $root.getTranslatedAcfValue(app, 'shippingInfosLink')})"></span>
					</div>

					<div class="XXflex XXflex--column relative">

						<!--
						<BaseInput
							class="BaseInput--addToCart"
							type="number"
							@input="changeNumberToAddToCart"
							:value="numberToAddToCart"
							:min="1"
							:max="99"
							:step="1"
						></BaseInput>
						-->

						<BaseButton
							v-if="post"
							class="LuBiblios__addToCartBtn price BaseButton--addToCart"
							:class="btnClasses"
							:label="$t('button.addToCart')"
							@click.native="addToCart()"
						></BaseButton>

						<div class="LuBiblios__addToCartMessage"
							:class="{'LuBiblios__addToCartMessage--showNoteSuccessMessage' : showNoteSuccessMessage }">
							<a 	class="LuBiblios__addToCartMessageCloseBtn" @click="showNoteSuccessMessage = !showNoteSuccessMessage">×
							</a>
							<span v-html="$t('message.productAddedToCart')"></span>
						</div>

					</div>

				</template>

				<!-- product ist vergriffen -->
				<template v-else>
					<div class="font font--mono font--autoSmooth">
						<span v-html="$t('message.productIsOut')"></span>
					</div>
				</template>
			</template>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import BaseButton from '@/components/Base/BaseButton.vue'
	import BaseInput from '@/components/Base/BaseInput.vue'
	import LuPrice from '@/components/LuPrice.vue'

	export default {
		name: 'LuBiblios',
		components: {
			BaseButton,
			BaseInput,
			LuPrice,
		},
		mixins: [],
		props: {
			post: [Object, Boolean],
		},
		data() {
			return {
				numberToAddToCart : 1,
				showBtnSuccessMessage : false,
				showNoteSuccessMessage : false,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			btnClasses(){
				let classes = []

				if( this.showBtnSuccessMessage ) classes.push('BaseButton--showBtnSuccessMessage')
				if( this.$root.currentLang == 'en' ) classes.push('BaseButton--en')
				if( this.$root.currentLang == 'de' ) classes.push('BaseButton--de')

				return classes
			}
		},
		methods: {
			changeNumberToAddToCart( newNumber ){
				this.numberToAddToCart = newNumber
			},
			addToCart(){ // commit to post.id to store as often as choosen
				const post = this.post
				const numberToAddToCart = this.numberToAddToCart

				for( let i = 0; i < numberToAddToCart; i++) {
					this.$store.commit('addToCart', post.id)
				}

				this.numberToAddToCart = 1 // not really needed

				// show success message and hide again after 2s
				setTimeout(() => { this.showBtnSuccessMessage = true }, 75)
				setTimeout(() => { this.showBtnSuccessMessage = false }, 2000)

				setTimeout(() => { this.showNoteSuccessMessage = true }, 300)
				//setTimeout(() => { this.showNoteSuccessMessage = false }, 4000)

				//this.showNoteSuccessMessage = true

				//this.$router.push({ name : 'CartView' })
			},
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";



	.LuBiblios {
		position: relative;

		&__price {
			display: block;
			transform: translateX(-0.07em);
		}
		&__priceInfos {
			padding-top: 0.5em;
			padding-bottom: 2em;
		}
		&__addToCartBtn {
			transition: box-shadow 0.1s ease;
		}
		&__addToCartBtn:hover {
			.shadow--cartButton;
		}
		&__addToCartMessage {
			position: relative;
			border: 1px solid currentColor;
			border-radius: 2px;
			padding: 0.5em; padding-right: 1.5em;
			padding-bottom: 0.75em;
			margin-top: 0.5em;

			&CloseBtn {
				//background-color: fade( red, 50 );

				position: absolute;
				top: 0; right: 0;
				padding: 0.25em;
				padding-right: 0.5em;
				line-height: 1em;
				border-bottom: none !important;
			}
		}
		&__addToCartMessage {
			transition: all 0.2s ease;
			transform: translateY(-100%);
			opacity: 0;
			pointer-events: none;
		}
		&__addToCartMessage--showNoteSuccessMessage {
			transform: translateY(0%);
			opacity: 1;
			pointer-events: auto;
		}

		// Btn Animation nachdem ein Produkt in den
		// Warenkorb gelegt wurde
		.BaseButton {
			&__inner,
			&__inner::after {
				transition: all 0.2s ease;
			}
			&__inner {
				position: relative;
				overflow: visible;
			}
			&__inner::after {
				//background-color: fade( red, 75 );

				position: absolute;
				top: 0; left: 0;
				width: 100%; height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				transform: translateY(100%);
				transform: translateY(-100%);
				white-space: pre;
			}

			&--de .BaseButton__inner::after {
				content: "✔ Hinzugefügt";
			}
			&--en .BaseButton__inner::after {
				content: "✔ Added";
			}
		}
		.BaseButton--showBtnSuccessMessage {
			pointer-events: none;

			.BaseButton__inner {
				transform: translateY(100%);
			}
			.BaseButton__inner::after {
				transform: translateY(-100%);
				//background-color: fade( black, 10 );
			}
		}

		a {
			color: inherit;
			border-bottom: 1px dotted;
		}
		a:hover {
			border-bottom: 1px solid;
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
