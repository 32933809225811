<template>
	<div class="LubokProductView view" v-if="viewIsReady">
		<LuMobileHeader
			class="app__mobileNav"
			v-if="$root.isSmallDevice"
		></LuMobileHeader>

		<div class="grid grid--pageTemplateProduct vSpace vSpace--page hSpace hSpace--page maxWidth maxWidth--page" v-if="post">
			<!-- header: product title, author -->
			<div>
				<LuPageHeader
					:title="$root.getTranslatedAcfValue(post.acf, 'author')"
					:subTitle="$root.getTranslatedAcfValue(post.acf, 'postTitle')"
					:big="true"
					v-if="post"
				></LuPageHeader>
			</div>
			<!-- content: slider -->
			<div>
				<LuGallery
					:backgroundSize="'contain'"
					:ratio="'16to10'"
					:gallery="gallery"
					:lineAbove="true"
					:isProductSlider="true"
					:hideCaptions="true"
					v-if="post"
				></LuGallery>

				<LuSpacer
					:lines="1"
					v-if="post && $root.getTranslatedAcfValue(post.acf, 'text')"
				></LuSpacer>
			</div>
			<!-- content: biblios -->
			<div>
				<LuBiblios
					:post="post"
				></LuBiblios>

				<LuSpacer
					:lines="$root.isSmallDevice ? 2 : 0"
				></LuSpacer>
			</div>
			<!-- content: text -->
			<div>
				<LuText
					:text="$root.getTranslatedAcfValue(post.acf, 'text')"
					:fontSize="$root.isSmallDevice ? 'medium' : 'big'"
					:lineAbove="true"
					:textOnBlack="true"
					v-if="post && $root.getTranslatedAcfValue(post.acf, 'text')"
				></LuText>

				<LuSpacer
					:lines="$root.isSmallDevice ? 2 : 4"
				></LuSpacer>
			</div>
			<!-- content: related products -->
			<div>
				<LuHeadline
					:text="$t('title.relatedProducts')"
					:size="'default'"
					:lineAbove="true"
					v-if="relatedProducts.length"
				></LuHeadline>

				<LuSpacer
					:lines="$root.isSmallDevice ? 0 : 2"
				></LuSpacer>

				<LuProductTeasers
					:products="relatedProducts"
				></LuProductTeasers>
			</div>
		</div>

		<LuNotFound class="color color--white" v-if="isNotFound"></LuNotFound>

		<!--
		<pre data-name="post">{{post}}</pre>
		<pre data-name="relatedProducts">{{relatedProducts}}</pre>
		 -->
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import ViewMixin from './View.mixin.js'
	import VueperSlides from '@/components/VueperSlides/VueperSlides.vue'
	import VueperSlide from '@/components/VueperSlides/VueperSlide.vue'
	import restHandler from '@/mixins/restHandler/v1/restHandler.mixin.js'
	import MhColor from '@/components/MhColor/MhColor.js'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import MhVibrantPalette from '@/components/MhVibrantPalette.vue'
	import MhSmartHeader from '@/components/MhSmartHeader/MhSmartHeader.vue'
	import MhDevGrid from '@/components/MhDevGrid/MhDevGrid.vue'

	import LuPrice from '@/components/LuPrice.vue'
	import LuBiblios from '@/components/LuBiblios.vue'
	import LuProductTeasers from '@/components/LuProductTeasers.vue'
	import LuPageHeader from '@/components/LuPageHeader.vue'
	import LuHeadline from '@/components/LuHeadline.vue'
	import LuText from '@/components/LuText.vue'
	import LuGallery from '@/components/LuGallery.vue'
	import LuSpacer from '@/components/LuSpacer.vue'
	import LuMobileHeader from '@/components/LuMobileHeader.vue'
	import LuNotFound from '@/components/LuNotFound.vue'

	export default {
		name: 'LuProductView',
		components: {
			MhImage,
			MhVibrantPalette,
			MhSmartHeader,
			MhDevGrid,
			LuPrice,
			VueperSlides,
			VueperSlide,
			LuBiblios,
			LuProductTeasers,
			LuPageHeader,
			LuHeadline,
			LuText,
			LuGallery,
			LuSpacer,
			LuMobileHeader,
			LuNotFound,
		},
		mixins: [
			restHandler,
			ViewMixin,
			MhColor,
		],
		props: {},
		data() {
			return {
				post            : null,
				relatedProducts : [],
				isNotFound      : false,
			}
		},
		watch: {},
		computed: {
			gallery(){
				const post = this.post
				let gallery = []

				if( post && post.acf && post.acf.gallery ){
					post.acf.gallery.forEach( item => {
						gallery.push( item )
					})
				}
				if( post && post.acf && post.acf.coverImage ){
					gallery.unshift( post.acf.coverImage )
				}

				return gallery
			},
		},
		methods: {
			doFetch() {
				// send loading to MhView to show the spinner
				EventBus.$emit('stateChanged', 'loading')

				// fetch post by slug
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts/',
					params : {
						slug : this.$route.params.slug,
					},
					callback : (response) => {
						const data       = response.data
						const posts      = data.result

						this.post = posts[0]
						this.viewIsReady = true // this comes from View.mixin.js

						if( !this.post ) this.isNotFound = true

						if( this.post ){
							this.vibrantColor = this.post.acf.vibrantPalette.vibrant ? this.getVibrantColor( this.post.acf.vibrantPalette.vibrant ) : false

							// fetch relatedProducts if there are any
							if( this._.isArray( this.post.acf.relatedProducts ) ){
								// loaded wird von doFetchRelated() emited
								this.doFetchRelated()
							}
							else{
								// send loaded to MhView to hide the spinner again
								EventBus.$emit('stateChanged', 'loaded')
								//EventBus.$emit('vibrantColorChanged', this.vibrantColor )
							}

							this.title = this.$root.getTranslatedAcfValue( this.post.acf, 'postTitle')
						}
						else{
							EventBus.$emit('stateChanged', 'loaded')
							this.title = ''
						}


					},
				})
			},
			doFetchRelated() {
				// fetch event by slug
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts/',
					params : {
						postIds : this.post.acf.relatedProducts,
					},
					callback : (response) => {
						const data            = response.data
						const relatedProducts = data.result

						this.relatedProducts = relatedProducts

						// send loaded to MhView to hide the spinner again
						EventBus.$emit('stateChanged', 'loaded')
						EventBus.$emit('vibrantColorChanged', this.vibrantColor )
					},
				})
			},
		},
		created() {},
		mounted() {
			// 1. fetch the product
			// 2. then fetch the related products
			this.doFetch()
		},
	}
</script>

<style lang="less">
</style>
